import * as ko from 'knockout';

declare global {
    interface KnockoutExtenders {
        cprClean(target: any, options: any): KnockoutObservable<any>;
    }
}

ko.extenders.cprClean = (target: any, options: any) => {
    // create a writeable computed observable to intercept writes to our observable
    const result = ko
        .computed({
            read: target, // always return the original observables value
            write: (newValue: string) => {
                const current = target();
                const valueToWrite =
                    newValue && newValue.replace(/[ \D]/gi, '');

                // only write if it changed
                if (valueToWrite !== current) {
                    target(valueToWrite);
                } else {
                    // if the stripped value is the same, but a different value was written,
                    // force a notification for the current field
                    if (newValue !== current) {
                        target.notifySubscribers(valueToWrite);
                    }
                }
            },
        })
        .extend({ notify: 'always' });

    // initialize with current value to make sure it is cleaned appropriately
    result(target());

    // return the new computed observable
    return result;
};
