import 'knockout.validation';
import * as ko from 'knockout';
import { v4 as uuid } from 'uuid';
import {
    IInsurancePolicyProvider,
    ISetPolicyNumberRequest,
    ISetPolicyNumberResponse,
    IStatus,
} from '../Common/interfaces';
import { Texts } from '../Common/Texts';
import { Utils } from '../Common/Utils';

export class ProfileInsuranceViewModel {
    public provider = ko.observable<IInsurancePolicyProvider>();

    public policyNumber = ko.observable('');
    public title = ko.observable('');
    public description = ko.observable('');
    public inputLabel = ko.observable('');
    public callToAction = ko.observable('');
    public isSubmitting = ko.observable(false);
    public interactedWithInput = ko.observable(false);

    public showInputField = ko.pureComputed(
        () => this.useIdentifierField && this.isOnFinalPage(),
    );
    public isOnFinalPage = ko.pureComputed(
        () =>
            !this.hasTwoPages ||
            this.description() == this.provider().Description2,
    );

    private hasTwoPages = false;
    private useIdentifierField = false;

    private validator: KnockoutValidationGroup;

    constructor(provider: IInsurancePolicyProvider, policyNumber?: string) {
        this.provider(provider);

        this.policyNumber.extend({
            required: {
                param: true,
                message: () => {
                    return this.provider()
                        ? this.provider().IdentifierTerm + ' skal udfyldes'
                        : '';
                },
            },
        });

        this.validator = ko.validatedObservable([this.policyNumber]);

        this.policyNumber(policyNumber);
        this.title(provider.Name);
        this.description(
            policyNumber && provider.PrefilledIdentifierDescription
                ? provider.PrefilledIdentifierDescription
                : provider.Description,
        );
        this.callToAction(provider.CallToAction);
        this.inputLabel(provider.IdentifierTerm);
        this.useIdentifierField = provider.ShowIdentifierField;
        this.hasTwoPages = provider.HasTwoPages;
    }

    public setPolicyNumber = () => {
        if (!this.isOnFinalPage()) {
            this.description(this.provider().Description2);
            this.callToAction(this.provider().CallToAction2);
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            return;
        }

        const redirect = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const returnUrl = urlParams.get('returnUrl') || '/p';
            location.href = returnUrl;
        };

        if (!this.useIdentifierField && !this.policyNumber()) {
            this.policyNumber(uuid());
        }
        this.interactedWithInput(true);
        this.policyNumber.clearError();

        if (!this.validator.isValid()) {
            this.validator.errors.showAllMessages(true);
            return;
        }

        if (this.isSubmitting()) return;

        this.isSubmitting(true);

        const ok = (
            response: IStatus<ISetPolicyNumberResponse>,
            textStatus: string,
            jqXhr: JQueryXHR,
        ): void => {
            if (response.HasErrors) {
                const message = response.Errors.map((x) => x.Value).join();
                this.policyNumber.setError(message);
                this.validator.errors.showAllMessages(true);
                this.isSubmitting(false);
                return;
            }
            redirect();
        };

        const error = (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ): void => {
            const message = Texts.getResource(
                'ErrorValidatingInsuranceMembership',
            );
            this.policyNumber.setError(message);
            this.validator.errors.showAllMessages(true);
            this.isSubmitting(false);
        };

        const request: ISetPolicyNumberRequest = {
            Number: this.policyNumber(),
            InsurancePolicyProviderType:
                this.provider().InsurancePolicyProviderType,
        };

        Utils.ajaxCall(
            'POST',
            'api/insurance/policy-number/set/v2',
            ok,
            error,
            JSON.stringify(request),
        );
    };
}
