import 'knockout.validation';
import * as ko from 'knockout';

import {
    IAddPartnerRequest,
    IAdultHouseholdMember,
    IDeleteAdultHouseholdMemberRequest,
    IStatus,
} from '../Common/interfaces';
import { Texts } from '../Common/Texts';
import { Utils } from '../Common/Utils';
import Modal from '../Common/Modal';
import { AlertViewModel } from './AlertViewModel';
import { fetch } from '@/Common/fetch';

export class PartnerModalViewModel {
    public partnerName: KnockoutObservable<string>;
    public partnerCpr: KnockoutObservable<string>;
    public partnerEmail: KnockoutObservable<string>;
    public CPRFormatted: KnockoutObservable<string>;
    public CPRConfirmed: KnockoutObservable<boolean>;
    public showModulus11Warning: KnockoutObservable<boolean>;
    public inputGroup: KnockoutValidationGroup;
    public alertModal: AlertViewModel;
    public selectedPartnerToDelete: IAdultHouseholdMember;

    constructor() {
        const self = this;
        self.alertModal = new AlertViewModel();

        self.partnerName = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('PartnerNameRequired'),
            },
            fullname: {
                param: true,
                message: Texts.getResource('PleaseEnterFullName'),
            },
        });

        self.partnerEmail = ko.observable(null).extend({
            required: {
                param: true,
                message: Texts.getResource('PartnerEmailRequired'),
            },
            maxLength: 75,
            email: {
                message: Texts.getResource('InvalidEmail'),
            },
        });

        self.partnerCpr = ko
            .observable('')
            .extend({ cprClean: true })
            .extend({
                required: {
                    param: true,
                    message: Texts.getResource('PartnerCprRequired'),
                },
                validCprLength: true,
                validCprDate: true,
                overAndIncludingCertainAge: 18,
            });

        self.CPRConfirmed = ko.observable(false);

        self.inputGroup = ko.validatedObservable([
            self.partnerName,
            self.partnerCpr,
            self.partnerEmail,
        ]);
        self.inputGroup.errors.showAllMessages(false);
        self.showModulus11Warning = ko.observable(false);

        self.selectedPartnerToDelete = null;

        self.CPRFormatted = ko.pureComputed(function () {
            return Utils.formatCpr(self.partnerCpr());
        });
    }

    public confirmCprIsValid = (): void => {
        this.CPRConfirmed(true);
        this.invitePartner();
    };

    public hideModulus11Warning = (): void => {
        this.showModulus11Warning(false);
    };

    public openInvitePartnerModal = () => {
        const self = this;
        self.partnerEmail(null);
        self.partnerName(null);
        self.partnerCpr(null);
        this.inputGroup.errors.showAllMessages(false);
        this.showModulus11Warning(false);
        this.CPRConfirmed(false);
        Modal.show('invite-partner-modal');
    };

    public openDeletePartnerModal(
        partnerToDelete: IAdultHouseholdMember,
    ): void {
        this.selectedPartnerToDelete = partnerToDelete;
        Modal.show('confirm-delete-modal');
    }
    public closeDeletePartnerModal(): void {
        this.selectedPartnerToDelete = null;
        Modal.close('confirm-delete-modal');
    }

    public invitePartner = async () => {
        const self = this;

        if (!this.inputGroup.isValid()) {
            this.inputGroup.errors.showAllMessages(true);
            return;
        }

        if (!Utils.cprModulusCheck(this.partnerCpr()) && !this.CPRConfirmed()) {
            this.showModulus11Warning(true);
            return;
        }

        const request: IAddPartnerRequest = {
            Name: self.partnerName(),
            Email: self.partnerEmail(),
            Cpr: self.partnerCpr(),
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/patients/adult-household-member',
                'POST',
                request,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('ErrorInvitePartner') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('invite-partner-modal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('ErrorInvitePartner') + error,
            );
            Modal.close('invite-partner-modal');
        }
    };

    public deletePartner = async () => {
        const self = this;

        const request: IDeleteAdultHouseholdMemberRequest = {
            Cpr: self.selectedPartnerToDelete.Cpr,
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/patients/adult-household-member/delete',
                'POST',
                request,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('ErrorDeletePartner') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('confirm-delete-modal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('ErrorDeletePartner') + error,
            );
            Modal.close('confirm-delete-modal');
        }
    };
}
