import Dropzone, { DropzoneOptions } from 'dropzone';
import * as ko from 'knockout';
import 'knockout.validation';
import { ConsultationTypeEnum, ServiceTypeEnum } from '../../Common/enums';
import {
    IOpenMessageConsultationRequest,
    IOpenMessageConsultationResponse,
} from '../../Common/interfaces';
import { Texts } from '../../Common/Texts';
import { Utils } from '../../Common/Utils';
import { AlertViewModel } from '../AlertViewModel';
import { ReceiveVideoCall } from '../consultations/ReceiveVideoCallFromDoctor';
import { Log } from '../../Common/Log';

enum Page {
    DetailsForm = 0,
    NotifyOwnDoctor = 1,
    Categories = 2,
}

export class BookEmailConsultationViewModel {
    public alertModal: AlertViewModel;
    public receiveVideoCall: ReceiveVideoCall;
    public name: string;
    public CPR: string;
    public phone: KnockoutObservable<string>;
    public subject: KnockoutObservable<string>;
    public description: KnockoutObservable<string>;
    public attachments: KnockoutObservableArray<string>;
    public error: KnockoutValidationGroup;
    public formValidation: KnockoutComputed<boolean>;
    public showSpinner: KnockoutObservable<boolean>;
    public notifyOwnDoctor: KnockoutObservable<boolean>;
    public shouldPay: KnockoutObservable<boolean>;
    public shouldPayLabel: KnockoutComputed<string>;
    // This must be defined before the usages, or they will fail on construction
    private currentPage = ko.observable(Page.NotifyOwnDoctor);
    private isQuestionnaireOnly = false;

    public shouldShowNotifyOwnDoctor = ko.computed(
        () => this.currentPage() == Page.NotifyOwnDoctor,
    );
    public shouldShowDetailsForm = ko.computed(
        () => this.currentPage() == Page.DetailsForm,
    );
    public shouldShowCategories = ko.computed(
        () => this.currentPage() == Page.Categories,
    );
    public category = ko.observable<string | null>(null);

    public isSubmitting = ko.observable(false);

    private existingMessageUuid: string | null = null;
    private onBehalfOfUuid: string | null = null;
    private consultationType: ConsultationTypeEnum;
    private serviceType: ServiceTypeEnum;
    private dropzone: Dropzone;
    private dropzoneSelector = '#dropzone';
    private consultationHasCategories: boolean;
    private questionnaireResult: any = null;

    private dropzoneCleaner: () => void;

    private getInitialPage = () => {
        if (this.isQuestionnaireOnly) {
            return Page.DetailsForm;
        }
        return Page.NotifyOwnDoctor;
    };

    constructor(
        consultationType: ConsultationTypeEnum,
        serviceType: ServiceTypeEnum,
        price: number,
        consultationHasCategories: boolean,
        name = '',
        cpr = '',
        messageUuid: string | null = null,
        subject = '',
        message = '',
        onBehalfOf: string | null = null,
        attachments: string[] = [],
        questionnaireResult: any,
        phone: string | null,
        isQuestionnaireOnly: boolean,
    ) {
        const self = this;
        self.isQuestionnaireOnly = isQuestionnaireOnly;
        self.currentPage(this.getInitialPage());
        self.name = name;
        self.CPR = cpr;
        this.consultationType = consultationType;
        this.serviceType = serviceType;
        this.consultationHasCategories = consultationHasCategories;

        self.existingMessageUuid = messageUuid;
        self.onBehalfOfUuid = onBehalfOf;

        self.questionnaireResult = questionnaireResult;

        self.phone = ko.observable(null).extend({
            pattern: {
                params: '^[+]?[0-9]{8,20}$',
                message: Texts.getResource('InvalidPhoneNumber'),
            },
            validation: {
                validator: function (val: string) {
                    return val !== null && val.trim() !== '';
                },
                message: Texts.getResource('PhoneNumberRequired'),
            },
            phone: true,
        });
        self.phone(phone);
        self.subject = ko.observable(subject).extend({
            required: {
                message: Texts.getResource('SubjectRequired'),
                param: true,
            },
        });
        self.description = ko.observable(message).extend({
            required: {
                message: Texts.get('FillOutMessage'),
                param: true,
            },
        });
        self.attachments = ko.observableArray(attachments);
        self.notifyOwnDoctor = ko.observable(false);
        self.shouldPay = ko.observable(price !== 0);
        self.shouldPayLabel = ko.computed(() => {
            let label = Texts.getResource('SendButton');
            if (this.shouldPay()) {
                label = Texts.getResource('PayButton');
            }

            return label;
        });

        self.alertModal = new AlertViewModel();
        self.receiveVideoCall = new ReceiveVideoCall();

        self.formValidation = ko.computed(() => {
            return (
                self.name !== '' &&
                self.CPR !== '' &&
                self.phone() !== '' &&
                self.subject() !== '' &&
                self.description() !== ''
            );
        });

        self.showSpinner = ko.observable(false);

        const dropzoneOptions: DropzoneOptions = {
            url: '/api/files/upload',
            method: 'POST',
            success: (file) => {
                const uid = JSON.parse(file.xhr.responseText);
                (file as any).uid = uid;
                this.attachments.push(uid);
            },
            init: function () {
                this.on('addedfile', function (file) {
                    if (file.size > dropzoneOptions.maxFilesize) {
                        alert(
                            `${Texts.getResource(
                                'UploadFailedTooLarge',
                            ).replace('{0}', file.name)}`,
                        );
                        self.dropzone.removeFile(file);
                    }

                    const dropzoneContainer =
                        document.getElementById('dropzone');
                    const previewElements =
                        document.querySelectorAll('.dz-preview');
                    if (previewElements.length !== 0) {
                        dropzoneContainer.style.height = 'auto';
                    }
                });
                this.on('removedfile', function (file) {
                    const dropzoneContainer =
                        document.getElementById('dropzone');
                    const previewElements =
                        document.querySelectorAll('.dz-preview');
                    if (previewElements.length === 0) {
                        dropzoneContainer.style.height = '0';
                    }
                });
            },
            addRemoveLinks: true,
            acceptedFiles: 'image/jpeg,image/png,image/gif,.pdf,.mov',
            maxFiles: 10,
            maxFilesize: 30_000_000,
        };
        self.dropzone = new Dropzone(self.dropzoneSelector, dropzoneOptions);

        self.dropzoneCleaner = () => {
            self.dropzone.removeAllFiles(true);
            $(self.dropzoneSelector).empty();
        };

        self.dropzone.on('removedfile', (file: any) => {
            self.attachments.remove(file.uid);
        });

        if (messageUuid != null) {
            $.get(`/api/files/attachments/${messageUuid}`, (data) => {
                $.each(data, (key, value) => {
                    const mockFile = {
                        name: value.FileName,
                        size: value.FileByteSize,
                    };
                    self.dropzone.emit('addedfile', mockFile);
                    self.dropzone.options.thumbnail.call(
                        self.dropzone,
                        mockFile,
                        '/api/files/' + value.UID,
                    );
                    self.dropzone.emit('complete', mockFile);
                });
            });
        }

        document
            .getElementById('attachment-input')
            .addEventListener('change', function () {
                $('.js-send-email-button').prop('disabled', true);
                $('.js-upload-anchor').prop('disabled', true);
                const input = this as HTMLInputElement;
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files.item(i);
                    const fd = new FormData();
                    fd.append('file', file);
                    $.ajax('/api/files/upload', {
                        method: 'POST',
                        success: (data) => {
                            self.attachments.push(data);
                            $('.js-send-email-button').prop('disabled', false);
                            $('.js-upload-anchor').prop('disabled', false);
                        },
                        error: (data) => {
                            self.alertModal.openAlert(
                                'Filen kunne ikke vedhæftes. Prøv venligst igen.',
                            );
                            $('.js-send-email-button').prop('disabled', false);
                            $('.js-upload-anchor').prop('disabled', false);
                        },
                        contentType: false,
                        processData: false,
                        data: fd,
                    });
                }
            });

        self.error = ko.validatedObservable(this);
        self.error.errors.showAllMessages(false);
    }

    public executeSendMessage(): void {
        this.sendMessage();
    }

    public sendMessage(): void {
        const self = this;

        if (!self.error.isValid()) {
            self.error.errors.showAllMessages();
            return;
        }

        this.isSubmitting(true);

        const callbackSucces = (
            response: IOpenMessageConsultationResponse,
            status: any,
            error: any,
        ): void => {
            if (response.RequiresPayment) {
                window.location.href =
                    window.location.origin +
                    '/payment/plan?consultation=' +
                    response.ConsultationGuid;
            } else {
                window.location.href =
                    window.location.origin +
                    '/consultations/' +
                    response.ConsultationGuid +
                    '/sentemail';
            }
        };

        const callbackError = (
            response: any,
            status: any,
            error: any,
        ): void => {
            this.isSubmitting(false);
            switch (response.status) {
                case 402:
                    self.alertModal.openAlert(
                        Texts.getResource('InvalidPayment'),
                    );
                    break;
                default:
                    self.alertModal.openAlert(
                        Texts.getResource('ErrorCreateConsultation'),
                    );
                    break;
            }
        };

        self.openMessageConsultation(callbackSucces, callbackError);
    }

    public getOpenMessageConsultationRequest(): IOpenMessageConsultationRequest {
        const self = this;

        const request: IOpenMessageConsultationRequest = {
            FromExistingMessageGuid: self.existingMessageUuid,
            FromFullName: self.name,
            CprNumber: self.CPR,
            MobileNumber: self.phone(),
            Subject: self.subject(),
            FileUids: self.attachments(),
            Message: self.description(),
            OnBehalfUID:
                self.onBehalfOfUuid === null ? null : self.onBehalfOfUuid,
            ConsultationType: this.consultationType,
            Category: this.category(),
            NotifyOwnDoctor: self.notifyOwnDoctor(),
            QuestionnaireResult: self.questionnaireResult,
        };

        return request;
    }

    public openMessageConsultation(
        successCallback: (
            response: IOpenMessageConsultationResponse,
            status: string,
            jqXhr: JQueryXHR,
        ) => any,
        errorCallback: (jqXhr: JQueryXHR, status: string, error: string) => any,
    ): void {
        const self = this;

        const consultationRequest = self.getOpenMessageConsultationRequest();

        Utils.ajaxCall(
            'POST',
            'api/consultations/message',
            successCallback,
            errorCallback,
            JSON.stringify(consultationRequest),
        );
    }

    public uploadAttachment = () => {
        $(this.dropzoneSelector).trigger('click');
    };

    public acceptNotifyOwnDoctor(): void {
        this.notifyOwnDoctor(true);
        this.currentPage(Page.DetailsForm);
    }

    public declineNotifyOwnDoctor(): void {
        this.notifyOwnDoctor(false);
        this.currentPage(Page.DetailsForm);
    }

    public detailsFormComplete(): void {
        if (this.consultationHasCategories) {
            this.currentPage(Page.Categories);
            this.scrollToTop();
        } else {
            this.executeSendMessage();
        }
    }
    public backToDetails(): void {
        this.currentPage(Page.DetailsForm);
        this.scrollToTop();
    }
    public categorySelected(): void {
        if (this.category()) {
            this.executeSendMessage();
        }
    }

    private scrollToTop() {
        const anchor = document.querySelector('#js-top-anchor');
        if (anchor) {
            anchor.scrollIntoView();
        }
    }

    public clearForm(): void {
        const self = this;

        Utils.ajaxCall(
            'DELETE',
            `api/consultations/messages/${self.existingMessageUuid}`,
            (response: any, status: string, jqXhr: JQueryXHR) => {
                if (jqXhr.status === 200) {
                    self.onBehalfOfUuid = null;
                    self.attachments.removeAll();
                    self.subject('');
                    self.description('');
                    self.dropzoneCleaner();

                    self.error.errors.showAllMessages(false);
                } else {
                    alert('Failed to clear message!');
                }
            },
            (response, status, error) => {
                Log.error('failed to delete message');
            },
        );
    }
}
